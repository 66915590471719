import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Session from "integrations/services/Session";
import API from "shared/constants/api";
import { traceLink } from "shared/services/GraphQL/link";
import Auth from "shared/services/InternalAuth";
import checkAndRefreshToken from "shared/utils/checkAndRefreshToken";
import getEnvironment from "shared/utils/getEnvironment";

const uri = API.INTEGRATIONS[getEnvironment()];

export const createAuthContext = async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  let session = Session.getSession();
  const isRefreshTokenEnabled = Session.isRefreshTokenEnabled();

  if (!session?.accessToken && isRefreshTokenEnabled) {
    await checkAndRefreshToken(Auth, Session);
    session = Session.getSession();
  }

  const authorization =
    session && isRefreshTokenEnabled
      ? `${session.tokenType} ${session.accessToken}`
      : session && session.idToken
        ? `${session.tokenType} ${session.idToken}`
        : "";
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization,
      "client-name": "Vidsy [web]",
      "client-version": "0.0.1",
    },
  };
};

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext(createAuthContext);

const link = authLink.concat(traceLink).concat(httpLink);

export default link;
